<template>
	<section v-if="reviewslider.length" class="main-content review-section">
		<div class="row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row slider-row">
			<div class="columns column12 review-slider">
				<div class="carousel">
					<div v-for="review in reviewslider.slice(-1)" :key="review.ID" class="review-slider-content">
						<p>{{ review.content }}</p>
						<hr />
						<div class="review-source">
							<strong>{{ review.firstName }}</strong>
							<span v-if="review.date">
								{{ $d(new Date(review.date.date.split(' ')[0]), 'short', $i18n.locale) }}
							</span>
							<div class="logo" :class="review.source" />
						</div>
					</div>
				</div>
				<div class="columns column12 button-row">
					<nuxt-link class="button" :to="localePath('reviews', locale)">
						{{ $t('moreReviews') }}
					</nuxt-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();

const { data: reviewslider } = await useWebsiteFetch('reviewslider', {
	query: { language: locale.value },
	key: `${locale.value}/reviewslider`,
});

defineProps({
	header: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
.review-section {
	background: var(--secondary-background-color);

	h2 {
		background: var(--secondary-background-color);
		padding: 10px 35px;
		z-index: 2;
		position: relative;
		display: inline-block;
		margin: 0 auto;
		max-width: 90%;
	}

	.slider-row {
		border: 10px solid var(--cta-color);
		padding: 100px 50px 50px;
		margin-top: -80px;
		z-index: 1;
		position: relative;
	}

	.button-row {
		margin: 40px auto 0;
	}

	p {
		font-size: 22px;
	}
}

.review-slider {
	overflow: hidden;
	max-width: 800px;
	margin: 0 auto;
	text-align: center;

	.review-slider-content {
		hr {
			margin: 25px 0;
			opacity: 0.2;
		}

		.review-source {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			gap: 15px;
			margin: 34px 0 0;

			.logo {
				width: 30px;
				height: 30px;
				background-size: 30px 30px;

				&.tripadvisor {
					background-image: url('~/assets/images/review-logo-tripadvisor.png');
				}

				&.bookingcom {
					background-image: url('~/assets/images/review-logo-bookingcom.png');
				}

				&.zoover {
					background-image: url('~/assets/images/review-logo-zoover.png');
				}

				&.expedia {
					background-image: url('~/assets/images/review-logo-expedia.png');
				}

				&.google {
					background-image: url('~/assets/images/review-logo-google.png');
				}

				&.hotel {
					background-image: url('~/assets/images/review-logo-hotel.png');
				}

				&.website {
					background-image: url('~/assets/images/review-logo-website.png');
				}
			}

			img {
				max-width: 30px;
				margin: -5px 0 0 10px;
			}

			i,
			svg {
				color: #d9bd8b;
			}
		}
	}
}

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;

		&::before {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;

		.review-slider-content p .review-source {
			display: block;
			float: right;
			margin: 40px 0 0 20px;
		}

		.review-slider-content .source .source-logo {
			margin: 20px 0 0;
		}
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
